
    .single-nav ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 10vh;
        background: hsl(0, 0%, 15%);
    }

        
    .single-nav li {
        /* display: flex; */
        flex: 1 0 auto;
        text-align: center;
    }

    
.single-nav li a {
    width: 100%;
    height: 100%;
	display: block;
	text-decoration: none;
	color: white;
}

.single-nav li a:hover {
    background: hsl(0, 0%, 25%);
}