.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button {
  margin: 2px;
}

.option {
  display: flex;
}
.count {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 10px;
  /* justify-self: center; */
  margin: auto;
  /* flex-direction: column; */
}

.inner__counter {
  display: flex;
}

.chart {
  background: #242424;
}

.lists {
  display: flex;
  justify-content: space-around;
}